import axios from "axios";

import {
	GET_ANALYSIS,
	SET_ANALYSIS_LOADING,
	GET_CAMPAIGN_ANALYSIS
} from "./types";

export const smsAnalysisData = timespan => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING
	});
	axios
		.get(`/api/analysis/no-of-sms/${timespan}`)
		.then(res => {
			dispatch({
				type: GET_ANALYSIS,
				payload: res.data
			});
		})
		.catch(err => {});
};

export const campaignAnalysisData = timespan => dispatch => {
	dispatch({
		type: SET_ANALYSIS_LOADING
	});
	axios
		.get(`/api/analysis/campaign/${timespan}`)
		.then(res => {
			dispatch({
				type: GET_CAMPAIGN_ANALYSIS,
				payload: res.data
			});
		})
		.catch(err => {});
};
