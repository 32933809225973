export default [
    {
        "houseNumber": "2605",
        "name": "धनबहादुर राई",
        "age": 75,
        "gender": "male",
        "ownerName": "धनबहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2622",
        "name": "रकमबहादुर राई",
        "age": 70,
        "gender": "male",
        "ownerName": "रकमबहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "9743082154",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2608",
        "name": "सुर्जबहदुर राई",
        "age": 70,
        "gender": "male",
        "ownerName": "सुर्जबहदुर राई",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2658",
        "name": "कृश्नबहादुर राई",
        "age": 76,
        "gender": "male",
        "ownerName": "कृश्नबहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3025",
        "name": "जय बहादुर बुढाथोकी ",
        "age": 70,
        "gender": "male",
        "ownerName": "जय बहादुर बुढाथोकी ",
        "answererRelation": "self_house_own",
        "contact": "9827760514",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2909",
        "name": "भिम बहादुर कार्की!",
        "age": 71,
        "gender": "male",
        "ownerName": "भिम बहादुर कार्की!",
        "answererRelation": "self_house_own",
        "contact": "9842876673",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2924",
        "name": "छत्र माया खड्का",
        "age": 75,
        "gender": "female",
        "ownerName": "रिता कुमारी खड्का",
        "answererRelation": "buhari",
        "contact": "9743077039",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2728",
        "name": "मनमाया थापा",
        "age": 77,
        "gender": "female",
        "ownerName": "तेजबहादुर थापा",
        "answererRelation": "mother",
        "contact": "9743052539",
        "secondaryContact": "9840907268"
    },
    {
        "houseNumber": "2947",
        "name": "मोहन माया कार्की ",
        "age": 85,
        "gender": "female",
        "ownerName": "चन्द्र बहादुर कार्की ",
        "answererRelation": "wife",
        "contact": "9861623865",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2699",
        "name": "असलमाया कार्कि",
        "age": 74,
        "gender": "female",
        "ownerName": "असलमाया कार्कि",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": "9862939353"
    },
    {
        "houseNumber": "2709",
        "name": "तृतमाया राई",
        "age": 79,
        "gender": "female",
        "ownerName": "तृतमाया राई",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2657",
        "name": "महालक्षी राई",
        "age": 81,
        "gender": "female",
        "ownerName": "महालक्षी राई",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2902",
        "name": "प्रेम बहादुर कार्की",
        "age": 84,
        "gender": "male",
        "ownerName": "राम बहादुर कार्की",
        "answererRelation": "son",
        "secondaryContact": "9863867715"
    },
    {
        "houseNumber": "3102",
        "name": "जेबनाथ कोइराला",
        "age": 74,
        "gender": "male",
        "ownerName": "दिपक कोइराला",
        "answererRelation": "son",
        "secondaryContact": "9861727416"
    },
    {
        "houseNumber": "3050",
        "name": "फुल सरिम्मा राई",
        "age": 73,
        "gender": "female",
        "ownerName": "फुल सरिम्मा राई",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2608",
        "name": "ललिमाया राई",
        "age": 70,
        "gender": "female",
        "ownerName": "सुर्जबहदुर राई",
        "answererRelation": "wife",
        "contact": "0.0",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2716",
        "name": "हेमबहादुर राई",
        "age": 88,
        "gender": "male",
        "ownerName": "हेमबहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "974210889E",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2913",
        "name": "छ्त्र बहादुर बि क",
        "age": 75,
        "gender": "male",
        "ownerName": "छ्त्र बहादुर बि क",
        "answererRelation": "self_house_own",
        "contact": "9743039902",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3006",
        "name": "डमर बहादुर बुढाथोकी ",
        "age": 80,
        "gender": "male",
        "ownerName": "डमर बहादुर बुढाथोकी ",
        "answererRelation": "self_house_own",
        "contact": "9842862852",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3120",
        "name": "लाटि माया तामाङ",
        "age": 85,
        "gender": "female",
        "ownerName": "लाटि माया तामाङ",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2624",
        "name": "कङ्लसरी राई",
        "age": 70,
        "gender": "female",
        "ownerName": "धनबहादुर राई",
        "answererRelation": "wife",
        "contact": "0.0",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2917",
        "name": "पदम माया बिष्ट",
        "age": 85,
        "gender": "female",
        "ownerName": "सारदा बिष्ट ",
        "answererRelation": "buhari",
        "secondaryContact": "9826723994"
    },
    {
        "houseNumber": "2624",
        "name": "धनबहादुर राई",
        "age": 72,
        "gender": "male",
        "ownerName": "धनबहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "3036",
        "name": "मनलक्षी राई",
        "age": 85,
        "gender": "female",
        "ownerName": "चक्र बहादुर राई",
        "answererRelation": "son",
        "contact": "984949305E",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2692",
        "name": "बमबहादुर राई",
        "age": 85,
        "gender": "male",
        "ownerName": "बिमला राई",
        "answererRelation": "sasura",
        "contact": "986295709E",
        "secondaryContact": "9863869603"
    },
    {
        "houseNumber": "3102",
        "name": "जिबमाया कोइराला ",
        "age": 74,
        "gender": "female",
        "ownerName": "दिपक कोइराला",
        "answererRelation": "son",
        "secondaryContact": "9861727416"
    },
    {
        "houseNumber": "3042",
        "name": "त्रिभुलाल राई",
        "age": 71,
        "gender": "male",
        "ownerName": "त्रिभुलाल राई",
        "answererRelation": "self_house_own",
        "contact": "9863825848",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2772",
        "name": "जङ्गधोज तामाङ्ग",
        "age": 80,
        "gender": "male",
        "ownerName": "जङ्गधोज तामाङ्ग",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2699",
        "name": "सोभितमान कार्कि",
        "age": 73,
        "gender": "male",
        "ownerName": "असलमाया कार्कि",
        "answererRelation": "husband",
        "contact": "0.0",
        "secondaryContact": "9862939353"
    },
    {
        "houseNumber": "2687",
        "name": "लक्ष्मिमाया थापा",
        "age": 74,
        "gender": "female",
        "ownerName": "लक्ष्मिमाया थापा",
        "answererRelation": "self_house_own",
        "contact": "9862812246",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2658",
        "name": "रत्नमाया राई",
        "age": 70,
        "gender": "female",
        "ownerName": "कृश्नबहादुर राई",
        "answererRelation": "wife",
        "contact": "0.0",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3041",
        "name": "आइत शेर राई",
        "age": 70,
        "gender": "male",
        "ownerName": "आइत शेर राई",
        "answererRelation": "self_house_own",
        "contact": "9827706067",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3043",
        "name": "मान बहादुर राई",
        "age": 75,
        "gender": "male",
        "ownerName": "मान बहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "9842579929",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2774",
        "name": "रणबहादुर तामाङ्ग",
        "age": 80,
        "gender": "male",
        "ownerName": "रणबहादुर तामाङ्ग",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3076",
        "name": "त्रिब हिरा राई",
        "age": 70,
        "gender": "female",
        "ownerName": "त्रिब हिरा राई",
        "answererRelation": "self_house_own",
        "contact": "984283709E",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2703",
        "name": "चन्द्रमाया राई",
        "age": 77,
        "gender": "female",
        "ownerName": "चन्द्रमाया राई",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2969",
        "name": "खद्गग बहादुर राई",
        "age": 80,
        "gender": "male",
        "ownerName": "युबराज राई",
        "answererRelation": "son",
        "contact": "9842312144",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3076",
        "name": "पन्चमान राई",
        "age": 76,
        "gender": "male",
        "ownerName": "त्रिब हिरा राई",
        "answererRelation": "wife",
        "contact": "984283709E",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2656",
        "name": "बिक्रबहादुर राई",
        "age": 73,
        "gender": "male",
        "ownerName": "बिक्रबहादुर राई",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2987",
        "name": "छबिलाल बि क",
        "age": 89,
        "gender": "male",
        "ownerName": "इन्द्र कुमारि बि क",
        "answererRelation": "buhari",
        "contact": "9807790938",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3092",
        "name": "मन माया बुढाथोकी ",
        "age": 78,
        "gender": "female",
        "ownerName": "चेत बहादुर कार्की ",
        "answererRelation": "sashu",
        "contact": "9862842312",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2705",
        "name": "भालुसेर राई",
        "age": 78,
        "gender": "male",
        "ownerName": "भालुसेर राई",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3099",
        "name": "सन्त बहादुर कार्की ",
        "age": 70,
        "gender": "male",
        "ownerName": "टांक बहादुर कार्की ",
        "answererRelation": "son",
        "contact": "9868842679",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3078",
        "name": "पर्व लक्ष्मी  राई",
        "age": 95,
        "gender": "female",
        "ownerName": "पर्व लक्ष्मी  राई",
        "answererRelation": "self_house_own",
        "contact": "981179521E",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2953",
        "name": "चन्द्र बहादुर खड्का ",
        "age": 75,
        "gender": "male",
        "ownerName": "चन्द्र बहादुर खड्का ",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2986",
        "name": "चन्द्रमाया भुजेल",
        "age": 74,
        "gender": "female",
        "ownerName": "नीर बहादुर भुजेल",
        "answererRelation": "son",
        "contact": "9863738875",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2769",
        "name": "मनमाया तामाङ्ग",
        "age": 70,
        "gender": "female",
        "ownerName": "कृश्नबहादुर तामाङ्ग",
        "answererRelation": "mother",
        "contact": "9868842658",
        "secondaryContact": "9867081919"
    },
    {
        "houseNumber": "2764",
        "name": "पदमकुमारी मगर",
        "age": 70,
        "gender": "female",
        "ownerName": "अमरबहादुर मगर",
        "answererRelation": "wife",
        "secondaryContact": "9862304155"
    },
    {
        "houseNumber": "2902",
        "name": "प्रेम माया कार्की",
        "age": 80,
        "gender": "female",
        "ownerName": "राम बहादुर कार्की",
        "answererRelation": "son",
        "secondaryContact": "9863867715"
    },
    {
        "houseNumber": "2969",
        "name": "बिष्णुमाया राई",
        "age": 73,
        "gender": "female",
        "ownerName": "युबराज राई",
        "answererRelation": "son",
        "contact": "9842312144",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3003",
        "name": "रंग बहादुर बुढाथोकी ",
        "age": 76,
        "gender": "male",
        "ownerName": "दुर्गामाया बुढाथोकी ",
        "answererRelation": "husband",
        "contact": "9803155499",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2986",
        "name": "धनसिङ भुजेल",
        "age": 75,
        "gender": "male",
        "ownerName": "नीर बहादुर भुजेल",
        "answererRelation": "jwai",
        "contact": "9863738875",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3031",
        "name": "धनमाया बुढाथोकी ",
        "age": 90,
        "gender": "female",
        "ownerName": "धनमाया बुढाथोकी ",
        "answererRelation": "self_house_own",
        "secondaryContact": "9842988086"
    },
    {
        "houseNumber": "2916",
        "name": "रत्न माया खड्का ",
        "age": 76,
        "gender": "female",
        "ownerName": "रत्न माया खड्का ",
        "answererRelation": "self_house_own",
        "secondaryContact": "9842576005"
    },
    {
        "houseNumber": "2753",
        "name": "कृश्नमाया कार्कि",
        "age": 85,
        "gender": "female",
        "ownerName": "कृश्नमाया कार्कि",
        "answererRelation": "self_house_own",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2740",
        "name": "तेक बहादुर राई",
        "age": 75,
        "gender": "male",
        "ownerName": "तेक बहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "9864680725",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3010",
        "name": "हमासरी राई",
        "age": 80,
        "gender": "female",
        "ownerName": "हमासरी राई",
        "answererRelation": "self_house_own",
        "secondaryContact": "9819937957"
    },
    {
        "houseNumber": "2766",
        "name": "केसरमाया तामाङ्ग",
        "age": 74,
        "gender": "female",
        "ownerName": "रमेश कुमार तामाङ्ग",
        "answererRelation": "mother",
        "contact": "9749629788",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2764",
        "name": "अमरबहादुर मगर",
        "age": 88,
        "gender": "male",
        "ownerName": "अमरबहादुर मगर",
        "answererRelation": "self_house_own",
        "secondaryContact": "9862304155"
    },
    {
        "houseNumber": "2686",
        "name": "तकबहादुर खदका",
        "age": 72,
        "gender": "male",
        "ownerName": "तकबहादुर खदका",
        "answererRelation": "self_house_own",
        "contact": "9862844484",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2645",
        "name": "श्यामबहादुर राई",
        "age": 81,
        "gender": "male",
        "ownerName": "सावरानी राई",
        "answererRelation": "sasura",
        "contact": "0.0",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2664",
        "name": "पदमसरा राई",
        "age": 81,
        "gender": "female",
        "ownerName": "जस्जित राई",
        "answererRelation": "mother",
        "contact": "9868198897",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "3121",
        "name": "सिर माया तामाङ",
        "age": 75,
        "gender": "female",
        "ownerName": "सिर माया तामाङ",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2913",
        "name": "छ्त्रमाया बि क",
        "age": 75,
        "gender": "female",
        "ownerName": "छ्त्र बहादुर बि क",
        "answererRelation": "wife",
        "contact": "9743039902",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2715",
        "name": "जनसेर राई",
        "age": 70,
        "gender": "male",
        "ownerName": "कमनसरी राई",
        "answererRelation": "sasura",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2637",
        "name": "तुलसेर राई",
        "age": 70,
        "gender": "male",
        "ownerName": "पुस्तमाया राई",
        "answererRelation": "husband",
        "secondaryContact": "0.0"
    },
    {
        "houseNumber": "2950",
        "name": "कृष्ण बहादुर तामाङ ",
        "age": 82,
        "gender": "male",
        "ownerName": "कृष्ण बहादुर तामाङ ",
        "answererRelation": "self_house_own",
        "contact": "9819992071",
        "secondaryContact": ""
    },
    {
        "houseNumber": "2670",
        "name": "इन्द्रमाया राई",
        "age": 70,
        "gender": "female",
        "ownerName": "इन्द्रमाया राई",
        "answererRelation": "self_house_own",
        "secondaryContact": ""
    },
    {
        "houseNumber": "3126",
        "name": "कबिराज मगर",
        "age": 80,
        "gender": "male",
        "ownerName": "झमक बहादुर मगर",
        "answererRelation": "son",
        "contact": "9741091179",
        "secondaryContact": "9860791359"
    },
    {
        "houseNumber": "2756",
        "name": "बलबहादुर राई",
        "age": 70,
        "gender": "male",
        "ownerName": "बलबहादुर राई",
        "answererRelation": "self_house_own",
        "contact": "0.0",
        "secondaryContact": ""
    }
]