import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Axios from "axios";
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Tooltips from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { Mention, Form, Button, Input, Tooltip, Icon, Empty } from "antd";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
const FormItem = Form.Item;
const { TextArea } = Input;
class IntegrationReactSelect extends React.Component {
	state = {
		groups: [],
		persons: [],

		dataTag: [],
		dataTags: []
	};

	NoOptionsMessage = props => {
		return (
			<Typography
				color="textSecondary"
				className={props.selectProps.classes.noOptionsMessage}
				{...props.innerProps}
			>
				{props.children}
			</Typography>
		);
	};

	inputComponent = ({ inputRef, ...props }) => {
		return <div ref={inputRef} {...props} />;
	};

	Control = props => {
		return (
			<TextField
				fullWidth
				InputProps={{
					inputComponent: this.inputComponent,
					inputProps: {
						className: props.selectProps.classes.input,
						inputRef: props.innerRef,
						children: props.children,
						...props.innerProps
					}
				}}
				{...props.selectProps.textFieldProps}
			/>
		);
	};

	getNameWithoutSymbol = name => name.substr(1, name.length); //return the text without first letter ie. @ or # from person or group

	Option = props => {
		const splitted = props.children.split(".");
		return (
			<MenuItem
				buttonRef={props.innerRef}
				selected={props.isFocused}
				component="div"
				style={{
					fontWeight: props.isSelected ? 500 : 400,
					justifyContent: "space-between"
				}}
				{...props.innerProps}
			>
				<span className="icon">
					{splitted[0] === "@" ? <PersonIcon /> : <GroupIcon />}
				</span>
				<span className={this.props.classes.name}>
					{/* {this.getNameWithoutSymbol(props.children)} */}
					{splitted[2]}
				</span>
				<span className="description">{splitted[1]}</span>
			</MenuItem>
		);
	}; //it renders the each menu

	Placeholder = props => {
		return (
			<Typography
				color="textSecondary"
				className={props.selectProps.classes.placeholder}
				{...props.innerProps}
			>
				{props.children}
			</Typography>
		);
	}; //placeholder of select textfield

	ValueContainer = props => {
		return (
			<div className={props.selectProps.classes.valueContainer}>
				{props.children}
			</div>
		);
	};

	MultiValue = props => {
		const splitted = props.children.split(".")[2];
		return null;
		// return (
		// 	<Chip
		// 		key={splitted}
		// 		tabIndex={-1}
		// 		label={splitted}
		// 		className={classNames(props.selectProps.classes.chip, {
		// 			[props.selectProps.classes.chipFocused]: props.isFocused
		// 		})}
		// 		onDelete={props.removeProps.onClick}
		// 		deleteIcon={<CancelIcon {...props.removeProps} />}
		// 	/>
		// );
	};

	Menu = props => {
		return (
			<Paper
				square
				className={props.selectProps.classes.paper}
				{...props.innerProps}
			>
				{props.children}
			</Paper>
		);
	};

	getSimType = phoneNumber => {
		if (phoneNumber.length === 10) {
			if (
				phoneNumber.startsWith("984") ||
				phoneNumber.startsWith("985") ||
				phoneNumber.startsWith("986")
			)
				return "Namaste";
			else if (
				phoneNumber.startsWith("980") ||
				phoneNumber.startsWith("981") ||
				phoneNumber.startsWith("982")
			)
				return "Ncell";
			else if (phoneNumber.startsWith("974") || phoneNumber.startsWith("975"))
				return "SKY";
			else if (phoneNumber.startsWith("961") || phoneNumber.startsWith("988"))
				return "Smart Cell";
		} else {
			return "Not a valid number";
		}
	}; // returns the telecom types ie. ncell, ntc

	getTags = () => {
		// const { dataTag } = this.state;
		const { customPhone, selectedMember } = this.props;
		// const arrData = customPhone.split(",").map(each => each.trim());
		const arrData = customPhone.split(",").filter(each => each && each.trim());

		return (
			<Grid container style={{ marginTop: 5, marginBottom: 10 }}>
				{selectedMember.length === 0 && arrData.length === 0 ? (
					<Grid
						style={{ marginTop: 20 }}
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						<Grid item>
							<Empty />
						</Grid>
					</Grid>
				) : (
					<div>
						{selectedMember.map((each, i) => {
							const splitted = each.label.split(".");
							const isGroup = splitted[0] === "#";
							return (
								<Tooltips
									TransitionComponent={Zoom}
									placement="top"
									title={
										isGroup
											? each.value.person.length + " persons"
											: `${each.value.primaryPhone} (${this.getSimType(
													each.value.primaryPhone
											  )})`
									}
									disableFocusListener
									disableTouchListener
								>
									<Chip
										key={i}
										style={{ marginRight: 5, marginBottom: 5 }}
										label={splitted[2]}
										onDelete={this.props.handleDeleteSelectedMember(i)}
										color={
											isGroup
												? "primary"
												: each.value.primaryPhone.startsWith("98") &&
												  each.value.primaryPhone.length === 10
												? "primary"
												: "secondary"
										}
									/>
								</Tooltips>
							);
						})}
						{arrData &&
							arrData.map((each, i) => {
								return (
									each && (
										<Tooltips
											style={{ marginLeft: 5 }}
											TransitionComponent={Zoom}
											placement="top"
											title={this.getSimType(each)}
											disableFocusListener
											disableTouchListener
										>
											<Chip
												key={i}
												style={{ marginRight: 5, marginBottom: 5 }}
												label={each}
												onDelete={this.props.handleDeleteCustomNumber(i)}
												color={
													each.startsWith("98") && each.length === 10
														? "primary"
														: "secondary"
												}
											/>
										</Tooltips>
									)
								);
							})}
					</div>
				)}
			</Grid>
		);
	}; // returns all the phoneNumber, persons and groups

	render() {
		const { classes, theme, selectedMember, customPhone } = this.props;
		const components = {
			Control: this.Control,
			Menu: this.Menu,
			MultiValue: this.MultiValue,
			NoOptionsMessage: this.NoOptionsMessage,
			Option: this.Option,
			Placeholder: this.Placeholder,
			ValueContainer: this.ValueContainer
		};

		const selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				"& input": {
					font: "inherit"
				}
			})
		};
		const { groups, persons } = this.state;
		let suggestions = persons.map(suggestion => {
			suggestion.type = 0;
			return {
				value: suggestion,
				isGroup: false,
				label: "@." + suggestion.primaryPhone + "." + suggestion.name
			};
		}); //render persons by prefixing @
		groups.map(group =>
			suggestions.push({
				value: group,
				isGroup: true,
				label: "#.(group)." + group.name
			})
		); //render groups by prefixing #

		//this returns all the members to whom message going to be sent
		// console.log("selectedMember", this.state.selectedMember);
		return (
			<Grid item xs={12} className={classes.root}>
				<Grid container direction="row" className={classes.tag}>
					{this.getTags()}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2">
						<span
							style={{ color: "#f44336", fontSize: "20px", fontWeight: 500 }}
						>
							Hint:
						</span>{" "}
						type
						<span style={{ color: "#2196f3", fontSize: "20px" }}> # </span> to
						show only group and
						<span style={{ color: "#2196f3", fontSize: "20px" }}> @ </span>
						for people only
					</Typography>

					<Form layout="vertical">
						<NoSsr>
							<Form.Item label="Add Group and Individuals">
								<Select
									classes={classes}
									styles={selectStyles}
									textFieldProps={{
										InputLabelProps: {
											shrink: true
										}
									}}
									options={suggestions}
									components={components}
									value={selectedMember}
									onChange={this.props.handleSelectedMember}
									placeholder="Choose groups or people"
									isMulti
								/>
							</Form.Item>
						</NoSsr>

						<Form.Item label="Add custom phone numbers">
							<TextArea
								style={{ width: "100%" }}
								value={customPhone}
								rows={8}
								placeholder="comma separated mobile number"
								onChange={this.props.handlePhoneNumberInput}
							/>
						</Form.Item>
					</Form>
				</Grid>
			</Grid>
		);
	}
	componentDidMount() {
		this.fetchPeople();
		this.fetchGroup();
	}

	fetchPeople = () => {
		Axios.get("/api/person/list")
			.then(res => {
				this.setState({
					persons: res.data
				});
			})
			.catch(err => {
				console.error(err);
			});
	};

	fetchGroup = () => {
		Axios.get("/api/group/list")
			.then(res => {
				this.setState({
					groups: res.data
				});
			})
			.catch(err => {
				console.error(err);
			});
	};
}

const styles = theme => ({
	root: {
		// flexGrow: 1,
		// height: 250
		// width: ""
	},
	input: {
		display: "flex",
		padding: 0
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center",
		overflow: "hidden"
	},
	chip: {
		margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === "light"
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		)
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
	},
	singleValue: {
		fontSize: 16
	},
	placeholder: {
		position: "absolute",
		left: 2,
		fontSize: 16
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0
	},
	divider: {
		height: theme.spacing.unit * 2
	},
	name: {
		marginRight: "auto",
		marginLeft: 5
	},
	tag: {
		marginTop: 15,
		padding: 10,
		marginBottom: 15,
		border: "4px dashed #ddd"
		// justifyContent: "center"
	}
});

IntegrationReactSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
