import { Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import { Button, message } from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader";
import CSVUploader from "../../common/csv";
import GridContainer from "../../common/Grid/GridContainer.jsx";
// core components
import GridItem from "../../common/Grid/GridItem.jsx";
import ListPeople from "./ListPeople";
import data from "./data";
class Home extends Component {
	state = {
		persons: [],
		status: false,
		data: []
	};

	success = msg => {
		message.success(msg);
	};

	error = msg => {
		message.error(msg);
	};

	componentDidMount() {
		this.fetchPerson();
	}

	fetchPerson = () => {
		Axios.get("/api/person/list")
			.then(res => {
				this.setState({
					persons: res.data
				});
			})
			.catch(err => {});
	};
	onDataFetched = data => {
		this.setState({
			status: true
		});
		Axios.post("/api/person/csv/json", data)
			.then(res => {
				console.log("res", res.data);
				if (res.data.success) {
					this.fetchPerson();
					this.setState({
						status: false
					});
				}
			})
			.catch(err => {});
	};
	findIndex = (values, type, rank, height) => {
		// logValue(value, logValue(value.map(val => val.data)));
		const index = values.findIndex(
			value =>
				value.sensor_type === type &&
				value.sensor_rank === rank &&
				value.sensor_height === height
		);

		return index;
	};

	onDataFetch = defaultData => {
		console.log("data", defaultData);
		// let header = [
		// 	"GPS Code",
		// 	"Easting",
		// 	"Northing",
		// 	"Structure Category",
		// 	"Name",
		// 	"Ward No.",
		// 	"Photo Code",
		// 	"Address"
		// ];
		let header = [
			"GPS Code",
			"Easting",
			"Northing",
			"Structure Category",
			"Name",
			"Ward No.",
			"Photo Code",
			"Address"
		];

		[...Array(defaultData.length - 1).keys()].map(each => {});

		// defaultData.reduce((datas, data) => {

		// }, []);
	};

	getJSONDisableData = () => {
		let tile = [];
		let header = [
			"क्र. स.",
			"घर नम्बर",
			"ब्यक्तिको पुरा नाम",
			"उमेर",
			"लिंग",
			"घरमुली को नाम",
			"घरमुली संग को नाता",
			"घरमुलीको फोन"
		];
		tile.push(header);
		data.forEach((each, i) => {
			let row = [
				i + 1,
				each.houseNumber,
				each.name,
				each.age,
				each.gender,
				each.ownerName,
				each.answererRelation,
				each.contact || each.secondaryContact
			];

			tile.push(row);
		});
		return tile;
	};

	getJSONData = () => {
		let tile = [];
		let header = ["name", "address", "email", "gender", "phone", "designation"];
		tile.push(header);
		this.state.persons.forEach(each => {
			let row = [
				each.name,
				each.address,
				each.email,
				each.gender,
				each.primaryPhone,
				each.designation ? each.designation.name : ""
			];

			tile.push(row);
		});
		return tile;
	};
	render() {
		const { classes } = this.props;
		const { status, persons } = this.state;
		console.log("persons", persons);
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						{this.state.status}
						<CardHeader
							color="primary"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between"
							}}
						>
							<Typography variant="h6" className={classes.cardTitleWhite}>
								People
							</Typography>
							<div className={classes.fab}>
								<CSVUploader
									loading={status}
									onDataFetched={this.onDataFetch}
									onError={this.error}
								/>
								{persons && (
									<CSVLink
										filename={`ward3e person_${moment().format(
											"MMMM_Do_YYYY,__h:mm:ss_a"
										)}_export.csv`}
										// data={this.getJSONData()}
										// data={this.getJSONDisableData()}
										data={this.state.data}
									>
										<Button
											type="primary"
											icon="download"
											size={"default"}
											style={{ marginRight: 10 }}
										>
											download disable csv ward5
										</Button>
									</CSVLink>
								)}

								<Link to="/people/add">
									<Fab color="primary" aria-label="Add">
										<AddIcon />
									</Fab>
								</Link>
							</div>
						</CardHeader>
						<CardBody>
							<ListPeople persons={persons} fetchPerson={this.fetchPerson} />
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		display: "flex",
		alignItems: "center"
	}
});

export default withStyles(styles)(Home);
