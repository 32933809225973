import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import licenseReducer from "./licenseReducer";
import analysisReducer from "./analysisReducer";

export default combineReducers({
	auth: authReducer,
	errors: errorReducer,
	license: licenseReducer,
	analysis: analysisReducer
});
