import { CURRENT_LICENSE, GET_ERRORS, SET_LOADING } from "../actions/types";
import isEmpty from "../utils/validation/is-empty";

const initialState = {
	loading: false,
	profile: {}
};
export default function(state = initialState, action) {
	switch (action.type) {
		case SET_LOADING:
			return {
				loading: true
			};
		case CURRENT_LICENSE:
			console.log("current_license", action.payload);
			return {
				...state,
				loading: false,
				profile: action.payload
			};
		default:
			return state;
	}
}
