// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import LogIcon from "@material-ui/icons/Description";
import MessageIcon from "@material-ui/icons/Textsms";
import DesignationIcon from "@material-ui/icons/PersonPin";
import InstitutionSurveyIcon from "@material-ui/icons/AccountBalance";

const dashboardRoutes = [
	{
		path: "/home",
		exact: true,
		hasChild: false,
		sidebarName: "Home Page",
		navbarName: "Smart SMS",
		icon: HomeIcon
	},
	{
		path: "/message",
		exact: true,
		hasChild: false,
		sidebarName: "Message",
		navbarName: "Message",
		icon: MessageIcon
	},
	{
		path: "/desgination",
		exact: true,
		hasChild: false,
		sidebarName: "Designation",
		navbarName: "Designation",
		icon: DesignationIcon
	},
	{
		path: "/people",
		exact: true,
		hasChild: false,
		sidebarName: "People",
		navbarName: "People",
		icon: HomeIcon
	},
	{
		path: "/group",
		exact: true,
		hasChild: false,
		sidebarName: "Group",
		navbarName: "Group",
		icon: HomeIcon
	},
	{
		path: "/log",
		hasChild: false,
		sidebarName: "Log Management",
		navbarName: "Log Management",
		icon: LogIcon
	},
	{
		path: "/admin",
		hasChild: false,
		sidebarName: "Admin",
		navbarName: "Admin",
		icon: PersonIcon
	}

	// {
	// 	path: "/report",
	// 	hasChild: false,
	// 	sidebarName: "Report",
	// 	navbarName: "Report",
	// 	icon: HomeIcon
	// }

	// { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
