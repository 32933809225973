import axios from "axios";

import { CURRENT_LICENSE, SET_LOADING } from "./types";

export const currentLicense = () => dispatch => {
	dispatch({
		type: SET_LOADING
	});
	axios
		.get("/api/license/current")
		.then(res => {
			console.log("action", res.data);
			dispatch({
				type: CURRENT_LICENSE,
				payload: res.data
			});
		})
		.catch(err => {});
};
