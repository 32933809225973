import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	DialogActions,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	Grid,
	Dialog
} from "@material-ui/core";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { deleteUser } from "../../../actions/authAction";
import AlertDialog from "../../common/frequent/dialog/AlertDialog";
import Axios from "axios";
// import Grid from "antd/lib/card/Grid";
import { Empty } from "antd";

// import PreviewModal from "../../common/frequent/dialog/PreviewModal";

class ListUser extends Component {
	state = {
		id: "",
		deleteDialog: false,
		open: false,
		designation: "",
		nameError: "",
		currentUserId: "",
		toBeEditedId: ""
	};

	removeUser = () => {
		const userId = this.state.currentUserId;
		this.props.removeDesignation(userId, () => {
			this.setState({ deleteDialog: false });
		});
	};

	handleDeleteOpen = designationID => event => {
		this.setState({ deleteDialog: true, currentUserId: designationID });
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	handleClose = () => {
		this.setState({ deleteDialog: false });
	};

	handleEdit = event => {
		const designation =
			this.props.designations &&
			this.props.designations.find(each => (each._id = event.target.id)).name;
		designation &&
			this.setState({
				editDialogOpen: true,
				toBeEditedId: event.target.id,
				designation,
				temp: designation
			});
	};

	editClose = event => {
		this.setState({ editDialogOpen: false, designation: "", toBeEditedId: "" });
	};

	onSubmit = () => {
		if (this.state.designation) {
			Axios.post("/api/designation/", {
				name: this.state.designation,
				id: this.state.toBeEditedId
			})
				.then(res => {
					this.editClose();
					this.props.fetchDesignation();
				})
				.catch(err => {});
		} else if (this.state.designation === this.state.temp) {
			this.setState({ nameError: "Please change something." });
		}
	};

	render() {
		const { classes, designations } = this.props;
		return (
			<Fragment>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Id</TableCell>
							<TableCell>Designation</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<Dialog
						disableBackdropClick
						minWidth="sm"
						open={this.state.editDialogOpen}
						onClose={this.editClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{"title"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<TextField
									onFocus={this.resetError}
									id="outlined-designation"
									label="Designation"
									error={this.state.nameError}
									helperText={this.state.nameError}
									className={classes.textField}
									value={this.state.designation}
									onChange={this.handleChange("designation")}
									margin="normal"
									variant="outlined"
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.editClose} color="primary">
								Cancel
							</Button>
							<Button onClick={this.onSubmit} color="primary" autoFocus>
								Save
							</Button>
						</DialogActions>
					</Dialog>

					<AlertDialog
						open={this.state.deleteDialog}
						handleClose={this.handleClose}
						negButtonClick={this.handleClose}
						posButtonClick={this.removeUser}
						title="Are you sure you want to delete the user ?"
						posButton="agree"
						negButton="disagree"
					/>

					<TableBody>
						{designations &&
							designations.map((designation, id) => (
								<TableRow key={id}>
									<TableCell component="th" scope="row">
										{id + 1}
									</TableCell>
									<TableCell align="left">{designation.name}</TableCell>

									<TableCell align="left">
										<Icon
											id={designation._id}
											onClick={this.handleEdit}
											className={classnames(classes.edit, classes.icon)}
										/>

										<DeleteIcon
											onClick={this.handleDeleteOpen(designation._id)}
											className={classnames(classes.delete, classes.icon)}
										/>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
				{designations.length === 0 && (
					<Grid
						style={{ marginTop: 20 }}
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						<Grid item>
							<Empty />
						</Grid>
					</Grid>
				)}
			</Fragment>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		float: "right"
	},
	icon: {
		margin: theme.spacing.unit * 1,
		cursor: "pointer"
	},
	preview: {
		color: lightBlue[500]
	},
	edit: {
		color: green[700]
	},
	delete: {
		color: red[500]
	},
	paper: {
		position: "absolute",
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		outline: "none"
	}
});

export default withStyles(styles)(ListUser);
