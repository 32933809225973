import React, { Component } from "react";
import GroupProfile from "./GroupProfile";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { Grid } from "@material-ui/core";
class EditGroup extends Component {
	state = {
		// person: "",
		name: "",
		photoUrl: 0,
		selectedMember: [],
		customPhone: ""
	};

	componentDidMount() {
		let groupId = this.props.match.params.groupId;
		Axios.get(`/api/group/one/${groupId}`)
			.then(res => {
				console.log("CDM name", res.data);
				let selectedMember = res.data.person.map(eachData => ({
					label: eachData.primaryPhone + "." + eachData.name,
					value: eachData
				}));
				this.setState({
					name: res.data.name,
					selectedMember,
					customPhone: res.data.phone
				});
			})
			.catch(err => {});
	}

	onSubmit = (values, csvData, cb) => {
		console.log("submitted");
		let groupBody = {};
		groupBody.id = this.props.match.params.groupId;
		groupBody.name = values.name;
		// groupBody.person = [];
		groupBody.csv = csvData;
		groupBody.person = this.state.selectedMember.map(each => each.value._id);
		groupBody.phone = this.state.customPhone;

		Axios.post("/api/group/csv/json", groupBody)
			.then(res => {
				cb("success", "Updated", "Group has been successfully updated");
				res.data.success && this.props.history.push("/group");
			})
			.catch(err => {});
	};

	handleChange = value => {
		this.setState({
			selectedMember: value
		});
	}; //autocomplete handlechange

	handleDeleteCustomNumber = index => () => {
		var customNumber = this.state.customPhone;
		var temp = customNumber.split(",");
		temp.splice(index, 1);

		var customPhone = temp.join(",");
		console.log("temp number", temp, customPhone);
		this.setState({ customPhone });
	}; //handle delete of custom input number

	handleDeleteSelectedMember = index => () => {
		var selectedMember = this.state.selectedMember;
		selectedMember.splice(index, 1);

		this.setState({ selectedMember });
	}; //handle delete of autocomplete

	handlePhoneNumberInput = event => {
		const value = event.target.value;

		this.setState({ customPhone: value });
	}; //handle input of custom number

	render() {
		console.log("selected edit", this.state.selectedMember);
		return (
			<Grid container spacing={24}>
				{!!!this.state.name ? (
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={!!!this.state.name}
							/>
						</Grid>
					</Grid>
				) : (
					<GroupProfile
						submitText="Save"
						title="Edit Group"
						// for search option
						handleChange={this.handleChange}
						handleDeleteCustomNumber={this.handleDeleteCustomNumber}
						handleDeleteSelectedMember={this.handleDeleteSelectedMember}
						handlePhoneNumberInput={this.handlePhoneNumberInput}
						selectedMember={this.state.selectedMember}
						customPhone={this.state.customPhone}
						onSubmit={this.onSubmit}
						name={this.state.name}
						person={this.state.person}
						photoUrl={this.state.photoUrl}
					/>
				)}
			</Grid>
		);
	}
}

export default EditGroup;
