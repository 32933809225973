import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography as Typo,
	Grid
} from "@material-ui/core";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { deleteUser } from "../../../actions/authAction";
import AlertDialog from "../../common/frequent/dialog/AlertDialog";
import { Empty } from "antd";
import moment from "moment";
// import PreviewModal from "../../common/frequent/dialog/PreviewModal";
import { Typography } from "antd";

const { Paragraph } = Typography;
class ListUser extends Component {
	state = {
		id: "",
		content: "",
		negButton: "",
		posButton: "",
		currentUserId: null,
		deleteDialog: false,
		open: false
	};

	removeUser = () => {
		const userId = this.state.currentUserId;
		deleteUser(userId).then(res => {
			console.log(res);
			this.handleClose();
			this.props.fetchUser();
		});
	};

	handleDeleteOpen = userId => event => {
		this.setState({ deleteDialog: true, currentUserId: userId });
	};

	handleClickOpen = () => {
		this.setState({ deleteDialog: true });
	};
	handleClose = () => {
		this.setState({ deleteDialog: false });
	};

	handlePreviewOpen = event => {
		this.setState({ open: true, id: event.target.id });
	};

	handlePreviewClose = () => {
		this.setState({ open: false, id: null });
	};
	render() {
		const { classes, messages } = this.props;
		console.log("message", messages);
		return (
			<Fragment>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Id</TableCell>
							<TableCell>Subject</TableCell>
							<TableCell>Send By</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>To</TableCell>
							<TableCell>Message Copy</TableCell>
							<TableCell>Date</TableCell>
						</TableRow>
					</TableHead>
					<AlertDialog
						open={this.state.deleteDialog}
						handleClose={this.handleClose}
						negButtonClick={this.handleClose}
						posButtonClick={this.removeUser}
						title="Are you sure you want to delete the user ?"
						posButton="agree"
						negButton="disagree"
					/>

					<TableBody>
						{messages.length > 0 &&
							messages.map((message, id) => (
								<TableRow key={id}>
									<TableCell component="th" scope="row">
										<Typo variant="body1">{id + 1}</Typo>
									</TableCell>
									<TableCell align="left">
										<Typo variant="body1">{message.subject}</Typo>
									</TableCell>
									<TableCell align="left">
										<Typo variant="body1">
											{message.sendBy.name.first +
												" " +
												message.sendBy.name.last}
										</Typo>
									</TableCell>
									<TableCell align="left">
										<Typo variant="body1">{message.type}</Typo>
									</TableCell>

									<TableCell align="left">
										<Typo variant="body1">{message.to.length || 0}</Typo>
									</TableCell>

									<TableCell align="left">
										<Paragraph copyable={{ text: message.message }}>
											&nbsp;
										</Paragraph>
									</TableCell>

									<TableCell align="left">
										<Typo variant="body1">
											{message &&
												message.createdAt &&
												moment(message.createdAt).fromNow()}
										</Typo>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
				{messages.length === 0 && (
					<Grid
						style={{ marginTop: 20 }}
						container
						direction="row"
						justify="center"
						alignItems="flex-start"
					>
						<Grid item>
							<Empty />
						</Grid>
					</Grid>
				)}
			</Fragment>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		float: "right"
	},
	icon: {
		margin: theme.spacing.unit * 1,
		cursor: "pointer"
	},
	preview: {
		color: lightBlue[500]
	},
	edit: {
		color: green[700]
	},
	delete: {
		color: red[500]
	},
	paper: {
		position: "absolute",
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		outline: "none"
	}
});

export default withStyles(styles)(ListUser);
