import {
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader";
import GridContainer from "../../common/Grid/GridContainer.jsx";
// core components
import Tooltip from "@material-ui/core/Tooltip";
import GridItem from "../../common/Grid/GridItem.jsx";
import ListUser from "./ListDesignation";
import TextField from "@material-ui/core/TextField";
class Home extends Component {
	state = {
		designations: [],
		addDialog: false,
		designation: "",
		nameError: ""
	};

	componentDidMount() {
		this.fetchDesignation();
	}

	fetchDesignation = () => {
		Axios.get("/api/designation/list")
			.then(res => {
				this.setState({
					designations: res.data
				});
			})
			.catch(err => {
				console.error(err);
			});
	};

	onSubmit = () => {
		if (this.state.designation) {
			Axios.post("/api/designation/", { name: this.state.designation })
				.then(res => {
					res.data.success && this.handleClose();
					this.fetchDesignation();
				})
				.catch(err => {});
		} else {
			this.setState({ nameError: "Designation cannot be empty" });
		}
	};

	onAddClick = () => {
		this.setState(({ addDialog }) => ({ addDialog: !addDialog }));
	};

	handleClose = () => {
		this.setState({ addDialog: false, designation: "" });
	};

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};
	resetError = () => this.setState({ nameError: "" });

	removeDesignation = (designationId, callback) => {
		console.log("remove called", designationId);
		Axios.delete(`/api/designation/delete/${designationId}`)
			.then(res => {
				console.log("user ");
				res.data.success && this.handleClose();
				callback();
				this.fetchDesignation();
			})
			.catch(err => {});
	};
	render() {
		const { classes } = this.props;
		console.log("designations", this.state.designations);
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Dialog
						disableBackdropClick
						minWidth="sm"
						open={this.state.addDialog}
						onClose={this.handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{"title"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<TextField
									onFocus={this.resetError}
									id="outlined-designation"
									label="Designation"
									error={this.state.nameError}
									helperText={this.state.nameError}
									className={classes.textField}
									value={this.state.designation}
									onChange={this.handleChange("designation")}
									margin="normal"
									variant="outlined"
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								Cancel
							</Button>
							<Button onClick={this.onSubmit} color="primary" autoFocus>
								Save
							</Button>
						</DialogActions>
					</Dialog>
					<Card>
						<CardHeader color="primary">
							<Typography variant="h6" className={classes.cardTitleWhite}>
								Designation
								<Tooltip title="थप्नुहोस">
									<Fab
										color="primary"
										aria-label="Add"
										className={classes.fab}
										onClick={this.onAddClick}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							</Typography>
						</CardHeader>
						<CardBody>
							<ListUser
								designations={this.state.designations}
								fetchDesignation={this.fetchDesignation}
								removeDesignation={this.removeDesignation}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 500
	},
	tableWrapper: {
		overflowX: "auto"
	},
	fab: {
		float: "right"
	}
});

export default withStyles(styles)(Home);
