import React, { Component } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { Form, Input, Button } from "antd";
import Axios from "axios";
import { Typography } from "antd";

const { Paragraph } = Typography;
export default class RestAPI extends Component {
	state = {
		data: {},
		link: ""
	};
	handleChange = obj => {
		this.setState({
			data: obj
		});
	};
	componentDidMount() {}

	submit = () => {
		if (this.state.data) {
			Axios.post("/api/json/", this.state.data.jsObject).then(res => {
				const host = `http://sms.hamropalika.org/api/json/${res.data.uniqueID}`;
				this.setState({
					link: host
				});
			});
		}
	};
	render() {
		return (
			<div>
				<div style={{ paddingTop: 50, marginLeft: 200 }}>
					{this.state.link ? (
						<Paragraph copyable={{ text: this.state.link }}>
							copy the link
						</Paragraph>
					) : (
						<p>Please save</p>
					)}
				</div>
				<div
					style={{
						marginTop: 10,
						marginLeft: 200,
						marginRight: 200
					}}
				>
					<JSONInput
						style={{ fontSize: "1rem" }}
						id="a_unique_id"
						// placeholder = { }
						// colors={"darktheme"}
						onChange={this.handleChange}
						locale={locale}
						width="100%"
						height="550px"
					/>

					<Button
						onClick={this.submit}
						type="primary"
						icon="send"
						size="large"
						style={{ marginTop: 10 }}
					>
						Send
					</Button>
				</div>
			</div>
		);
	}
}
