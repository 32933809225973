export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_COUNT = "GET_COUNT";
export const GET_DEATHSEARCH = "GET_DEATHSEARCH";
export const SET_LOADING = "SET_LOADING";
export const SET_ANALYSIS_LOADING = "SET_ANALYSIS_LOADING";
export const GET_ANALYSIS = "GET_ANALYSIS";
export const GET_CAMPAIGN_ANALYSIS = "GET_CAMPAIGN_ANALYSIS";
export const GET_HOUSE_DETAIL = "GET_HOUSE_DETAIL";
export const GET_FAMILY_BY_PHONE = "GET_FAMILY_BY_PHONE";
export const GET_MEMBER = "GET_MEMBER";
export const HOUSE_LOADING = "HOUSE_LOADING";
export const ADVANCE_SEARCH = "ADVANCE_SEARCH";
export const ADVANCE_LOAD = "ADVANCE_LOAD";
export const GET_PROGRESS = "GET_PROGRESS";
export const SET_PROGRESS_LOAD = "SET_PROGRESS_LOAD";
export const CURRENT_LICENSE = "CURRENT_LICENSE";
