import React, { Component } from "react";
import AccountBalanceWallet from "@material-ui/icons/AddShoppingCart";
import LocalATM from "@material-ui/icons/LocalAtm";
import Ballot from "@material-ui/icons/Ballot";

import { Grid, Typography, withStyles, Paper } from "@material-ui/core";
import { Divider, Button } from "antd";

class Wallet extends Component {
	render() {
		const {
			classes,
			creditsAvailable,
			totalCreditConsumed,
			lastBalanceAdded,
			smsSent
		} = this.props;
		return (
			<Paper elevation={2} className={classes.container}>
				<Grid
					container
					direction="column"
					justify="flex-start"
					alignItems="center"
				>
					<Grid item>
						<Typography variant="h4">My Wallet</Typography>
					</Grid>
				</Grid>

				<Grid item className={classes.creditBox}>
					<LocalATM style={{ fontSize: 30, color: "rgb(32, 156, 47)" }} />
					<Typography
						variant="body2"
						style={{ fontWeight: 600, marginLeft: 10 }}
					>
						Credits Available :
					</Typography>
					<Typography
						variant="body2"
						style={{ fontWeight: 500, marginLeft: 10 }}
					>
						{creditsAvailable}
					</Typography>
				</Grid>

				<Grid item className={classes.creditBox}>
					<LocalATM style={{ fontSize: 30, color: "#f95d65" }} />
					<Typography
						variant="body2"
						style={{ fontWeight: 600, marginLeft: 10 }}
					>
						Total Credit Consumed :
					</Typography>
					<Typography
						variant="body2"
						style={{ fontWeight: 500, marginLeft: 10 }}
					>
						{totalCreditConsumed}
					</Typography>
				</Grid>

				<Grid item className={classes.creditBox}>
					<AccountBalanceWallet style={{ fontSize: 30, color: "#ff9800" }} />
					<Typography
						variant="body2"
						style={{ fontWeight: 600, marginLeft: 10 }}
					>
						Last Balance Added :
					</Typography>
					<Typography
						variant="body2"
						style={{ fontWeight: 500, marginLeft: 10 }}
					>
						{lastBalanceAdded}
					</Typography>
				</Grid>

				<Divider />

				<Grid
					container
					direction="row"
					justify="flex-end"
					alignItems="flex-start"
				>
					<Grid item>
						<Button
							type="primary"
							icon="send"
							size="large"
							// style={{ justifyContent: "right" }}
						>
							Request for Credits
						</Button>
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

const styles = themes => ({
	container: {
		marginTop: 20,
		padding: 10
	},
	creditBox: {
		display: "flex",
		marginTop: 10,
		verticalAlign: "baseline",
		alignItems: "center"
	}
});
export default withStyles(styles)(Wallet);
