import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [reduxThunk];

const store = createStore(
	rootReducer,
	initialState,
	compose(applyMiddleware(...middleware))
);

export default store;
