import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import MTypography from "@material-ui/core/Typography";
import { Button, Form, Input, Switch, Typography } from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-images-uploader/font.css";
import "react-images-uploader/styles.css";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
// import CardFooter from "../../common/Card/CardFooter.jsx";
import CardHeader from "../../common/Card/CardHeader.jsx";
import SearchOption from "./SearchOption.jsx";
import Wallet from "./Wallet";

const { Title } = Typography;
const ButtonGroup = Button.Group;
const { TextArea } = Input;

class ConstructMessage extends Component {
	state = {
		errors: {},
		subject: "",
		message: "",
		pageCount: 1,
		remaining: 160,
		typed: 0,
		creditsAvailable: 0,
		totalCreditConsumed: 0,
		lastBalanceAdded: 0,
		smsSent: 0,
		selectedMember: [],
		customPhone: "",
		scheduleFlag: false,
		scheduleDate: new Date()
	};
	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSelectedMember = value => {
		this.setState({
			selectedMember: value
		});
	};

	handleMessageInput = event => {
		let message = event.target.value;
		let typed = message.length;
		let remaining = 160 - (typed % 160);
		let pageCount = Math.ceil(typed / 159);
		this.setState({ message, pageCount, typed, remaining });
	};

	componentDidMount() {
		this.fetchCredits();
	}

	fetchCredits = () => {
		Axios.get("/api/message/credit/fetch")
			.then(res => {
				this.setState({
					creditsAvailable: res.data.creditsAvailable,
					totalCreditConsumed: res.data.totalCreditConsumed,
					lastBalanceAdded: res.data.lastBalanceAdded,
					smsSent: res.data.smsSent
				});
			})
			.catch(err => {
				console.error(err);
			});
	};

	onSubmit = () => {
		const {
			subject,
			pageCount,
			typed,
			message,
			selectedMember,
			customPhone,
			scheduleFlag,
			scheduleDate
		} = this.state;
		let messageBody = {};
		messageBody.subject = subject;

		// messageBody.group = selectedMember.reduce((acc, curr) => {
		// 	if (curr.isGroup) return acc.push(curr.value._id);
		// }, []);

		// messageBody.people = selectedMember.reduce((acc, curr) => {
		// 	if (!curr.isGroup) return acc.push(curr.value._id);
		// }, []);
		messageBody.page = pageCount;
		messageBody.character = typed;
		messageBody.group = selectedMember
			.filter(each => each.isGroup)
			.map(each => each.value._id);
		messageBody.people = selectedMember
			.filter(each => each.isGroup == false)
			.map(each => each.value._id);
		messageBody.phone = customPhone;
		messageBody.message = message;

		messageBody.to = [];
		if (customPhone)
			messageBody.to = messageBody.to.concat(
				customPhone.split(",").map(item => item.trim())
			);
		messageBody.type = messageBody.to.length > 1 ? "bulk" : "single";
		if (selectedMember) {
			//fetch all phone numbers of custom, person from group and person from person.
			selectedMember.map(each => {
				if (each.isGroup) {
					// if selected is a group
					// group may have comma separated phone numbers ; fetch them
					if (each.value.phone !== "") {
						each.value.phone.split(",").map(i => messageBody.to.push(i));
					}

					// fetch person from group
					if (each.value.person.length > 0) {
						each.value.person.map(each =>
							messageBody.to.push(each.primaryPhone)
						);
					}
				} else {
					messageBody.to.push(each.value.primaryPhone);
				}
			});
		}
		messageBody.scheduleFlag = scheduleFlag;
		if (scheduleFlag) messageBody.scheduleDate = scheduleDate;

		Axios.post("/api/message/construct", messageBody)
			.then(res => {
				res.data.success && this.props.history.push("/message");
			})
			.catch(err => {});
	};

	handlePhoneNumberInput = event => {
		const value = event.target.value;

		this.setState({ customPhone: value });
	};

	handleDeleteCustomNumber = index => () => {
		var customNumber = this.state.customPhone;
		var temp = customNumber.split(",");
		temp.splice(index, 1);

		var customPhone = temp.join(",");
		console.log("temp number", temp, customPhone);
		this.setState({ customPhone });
	};

	handleDeleteSelectedMember = index => () => {
		var selectedMember = this.state.selectedMember;
		selectedMember.splice(index, 1);

		this.setState({ selectedMember });
	};

	onScheduledDataChange = checked => {
		this.setState({
			scheduleFlag: checked
		});
	};

	handleDateChange = date => {
		const momemntDate = moment(date).format();
		console.log(date, momemntDate);
		this.setState({
			scheduleDate: date
		});
	};

	render() {
		const { classes } = this.props;
		const {
			creditsAvailable,
			totalCreditConsumed,
			lastBalanceAdded,
			smsSent,
			errors,
			subject,
			pageCount,
			remaining,
			typed,
			message,
			selectedMember,
			customPhone
		} = this.state;
		return (
			<Card profile>
				<CardHeader color="primary">
					<MTypography variant="h5" className={classes.cardTitleWhite}>
						Construct a message
					</MTypography>
					<MTypography variant="h6" className={classes.cardCategoryWhite}>
						Send single/bulk right now or scheduled
					</MTypography>
				</CardHeader>
				<CardBody>
					<Grid container direction="row" spacing={24}>
						<Grid item xs={8} direction="column">
							<Grid item xs={12}>
								<Form layout="vertical">
									<Form.Item label="Campaign name">
										<Input
											// addonBefore={<SubjectIcon />}
											id="subject"
											name="subject"
											value={this.state.subject}
											error={errors.subject}
											placeholder="subject"
											onChange={this.handleChange}
											margin="normal"
											variant="outlined"
										/>
									</Form.Item>
								</Form>
							</Grid>

							<SearchOption
								handleSelectedMember={this.handleSelectedMember}
								selectedMember={selectedMember}
								customPhone={customPhone}
								handlePhoneNumberInput={this.handlePhoneNumberInput}
								handleDeleteCustomNumber={this.handleDeleteCustomNumber}
								handleDeleteSelectedMember={this.handleDeleteSelectedMember}
							/>
							<Grid item xs={12} style={{ marginTop: 2 }}>
								<ButtonGroup size="large">
									<Button type="primary">{`Page Count:${pageCount}`}</Button>
									<Button type="primary">
										{`Remaining: ${remaining}`}
										{/* <Icon type="right" /> */}
									</Button>
									<Button type="primary">
										{`typed: ${typed}`}
										{/* <Icon type="right" /> */}
									</Button>
								</ButtonGroup>
								<TextArea
									value={message}
									name="message"
									rows={8}
									onChange={this.handleMessageInput}
								/>
							</Grid>

							{/* <Grid
								container
								direction="row"
								justify="flex-start"
								alignItems="flex-start"
							>
								<Grid
									item
									xs={12}
									style={{
										display: "flex",
										justifyItems: "center",
										marginTop: 10,
										marginBottom: 10
									}}
								>
									<p>Schedule for some other time</p>
									<Switch
										value={this.state.scheduleFlag}
										onChange={this.onScheduledDataChange}
										style={{ marginLeft: 10 }}
									/>
								</Grid>
								{this.state.scheduleFlag && (
									<Grid container>
										<DatePicker
											selected={this.state.scheduleDate}
											onChange={this.handleDateChange}
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={15}
											dateFormat="MMMM d, yyyy h:mm aa"
											timeCaption="time"
											minDate={new Date()}
											placeholderText="Select scheduled date and time"
										/>
									</Grid>
								)}
							</Grid> */}

							<Grid
								container
								direction="row"
								justify="flex-end"
								alignItems="flex-start"
							>
								<Grid item>
									<Button
										onClick={this.onSubmit}
										type="primary"
										icon="send"
										size="large"
										style={{ marginTop: 10 }}
									>
										Send
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4} container direction="column">
							<Wallet
								creditsAvailable={this.state.creditsAvailable}
								totalCreditConsumed={this.state.totalCreditConsumed}
								lastBalanceAdded={this.state.lastBalanceAdded}
								smsSent={this.state.smsSent}
							/>
						</Grid>
					</Grid>
				</CardBody>
			</Card>
		);
	}
}

const styles = theme => ({
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	},
	textSize: {
		display: "flex"
	},
	container: {
		width: "100px",
		height: "100px",
		borderRadius: "100px",
		background: "#9C27B0"
	},
	name: {
		width: "100%",
		textAlign: "center",
		color: "white",
		fontSize: "28px",
		lineHeight: "100px"
	}
});

export default withStyles(styles)(ConstructMessage);
